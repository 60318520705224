.blog-single {
	$this: &;

	&__row {
		display: flex;
		flex-flow: row wrap;

		&.top {
			margin-bottom: 25px;

			#{$this}__content {
				img {
					margin-bottom: 0;
				}
			}
		}
	}

	&__spacer,
	&__content,
	&__auth-date,
	&__related {
		flex: 0 1 auto;

		&.col-20 {
			width: 20%;
		}

		&.col-60 {
			padding: 0 25px;
			width: 60%;
		}
	}

	&__meta {
		@extend %o-text-regular-14;
		color: $color-gray-1;
		letter-spacing: 4px;
		line-height: 1.2;
		margin-bottom: 15px;
		text-transform: uppercase;
	}

	&__title {
		@extend %o-title-bold-24;
		color: $color-gray-1;
		line-height: 1.2;
		margin-bottom: 25px;
		margin-top: 0;
	}

	&__auth-date {
		@media only screen and (max-width: 809px) {
			margin-top: 25px;
			width: 100%;
		}
	}

	&__author,
	&__date {
		@extend %o-text-bold-16;
		color: $color-black-1;
		line-height: 1.2;
		margin-bottom: 20px;
		padding-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	&__author {
		border-bottom: 1px solid $color-black-1;
	}

	&__content {
		@media only screen and (max-width: 1079px) {
			&.col-60 {
				padding-right: 0;
				width: 80%;
			}
		}

		@media only screen and (max-width: 809px) {
			&.col-60 {
				padding-left: 0;
				width: 100%;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $color-gray-1;
			font-weight: 700;
		}

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 20px;
		}

		h3 {
			font-size: 18px;
		}

		h4 {
			font-size: 18px;
		}

		h5 {
			font-size: 16px;
		}

		h6 {
			font-size: 14px;
		}

		img {
			margin-bottom: 25px;
		}

		blockquote {
			margin: 0 0 25px;
			padding-left: 65px;
			position: relative;
			width: 65%;

			@media only screen and (max-width: 1080px) {
				width: 100%;
			}

			&:before {
				content: url("../../assets/images/orange-quote.svg");
				left: 0;
				line-height: 1;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			p {
				@extend %o-text-bold-16;
				color: $color-gray-1;
				line-height: 1.6;
			}
		}

		p {
			@extend %o-text-regular-16;
			color: $color-gray-1;
			margin-bottom: 25px;
		}

		ul {
			margin-bottom: 30px;
			margin-left: 22px;

			> li {
				@extend %o-text-regular-16;
				color: $color-gray-1;
				line-height: 1.6;
				margin-bottom: 10px;
				text-indent: -22px;

				> ul {
					margin-bottom: 10px;
					margin-left: 30px;
				}
			}
		}

		.bt-link {
			font-weight: 700;
		}
	}

	&__related {
		@media only screen and (max-width: 1079px) {
			&.col-20 {
				margin-top: 50px;
				width: 100%;
			}
		}
	}

	&__related-title {
		@extend %o-title-bold-24;
		color: $color-gray-1;
		line-height: 1.2;
		margin-bottom: 50px;
		margin-top: 0;

		@media only screen and (max-width: 1079px) {
			text-align: center;
		}
	}

	&__related-items {
		@media only screen and (max-width: 1079px) {
			display: flex;
			flex-flow: row wrap;
			margin-left: -12.5px;
			width: calc(100% + 25px);
		}

		@media only screen and (max-width: 809px) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__related-item {
		margin-bottom: 50px;
		position: relative;

		@media only screen and (max-width: 1079px) {
			margin-bottom: 0;
			padding: 0 12.5px;
			width: 33.33%;
		}

		@media only screen and (max-width: 809px) {
			margin-bottom: 50px;
			padding: 0;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__related-item-img {
		@extend %background-image;
		height: 175px;
		margin-bottom: 15px;
	}

	&__related-item-title {
		@extend %o-title-bold-18;
		color: $color-black-1;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}
}
