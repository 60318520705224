.duo-content-d {
	$this: &;

	&.col-gap-40 {
		$gap: 2rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
		}
	}

	&.mobile-breakpoint-1079 {
		#{$this}__row {
			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			&.content-max-width-auto {
				@include breakpoint(1080px up) {
					@include auto();
				}
			}

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 4rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		#{$this}__f1 {
			@include breakpoint(1079px down) {
				max-width: 53rem;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&.mobile-breakpoint-767 {
		#{$this}__row {
			@include breakpoint(767px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			&.content-max-width-auto {
				@include breakpoint(768px up) {
					@include auto();
				}
			}

			@include breakpoint(767px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 4rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		#{$this}__f1 {
			@include breakpoint(767px down) {
				max-width: 53rem;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&__col {
		&.col-20 {
			width: 20%;
		}

		&.col-25 {
			width: 25%;
		}

		&.col-33 {
			width: 33.3333%;
		}

		&.col-50 {
			width: 50%;
		}
	}

	&__title,
	&__text,
	&__img,
	&__q1,
	&__t1 {
		margin-bottom: 4rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		@extend %o-title-bold-42;

		&.color-text-white {
			color: $color-white-1;
		}

		&.font-size-25 {
			font-size: 2.5rem;
		}

		&.margin-bottom-15 {
			margin-bottom: 1.5rem;
		}

		&.mobile-text-center {
			@include breakpoint(1079px down) {
				text-align: center;
			}
		}
	}

	&__text {
		&.color-text-white {
			li,
			p {
				color: $color-white-1;
			}
		}

		&.mobile-text-center {
			@include breakpoint(1079px down) {
				text-align: center;
			}
		}

		.button-box {
			margin-top: 3rem;
		}
	}

	&__img {
		&.phone-text-center {
			@include breakpoint(767px down) {
				text-align: center;
			}
		}

		&.mobile-text-center {
			@include breakpoint(1079px down) {
				text-align: center;
			}
		}
	}

	&__q1 {
		&.color-text-white {
			#{$this}__q1-text {
				li,
				p {
					color: $color-white-1;
				}
			}
		}
	}

	&__q1-top,
	&__q1-text {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__q1-top {
		#{$this}__q1-img {
			margin-left: 2rem;
		}
	}

	&__c1 {
		padding: 4rem;
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__c1 {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@extend %o-title-bold-25;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		p {
			@extend %o-text-regular-16;
		}
	}

	&__t1 {
		&.col-gap-20 {
			$gap: 1rem;

			#{$this}__t1-col {
				margin-bottom: 3rem;
			}

			@include breakpoint(768px up) {
				#{$this}__t1-row {
					margin-left: -$gap;
					margin-right: -$gap;
				}

				#{$this}__t1-col {
					padding-left: $gap;
					padding-right: $gap;
				}
			}

			@include breakpoint(767px down) {
				#{$this}__t1-col {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		&.content-font-size-16 {
			#{$this}__t1-text {
				li,
				p {
					font-size: 1.6rem;
				}
			}
		}
	}

	&__t1-col {
		&.col-50 {
			@include breakpoint(768px up) {
				width: 50%;
			}
		}
	}

	&__t1-title {
		@extend %o-title-bold-25;
	}

	&__t1-link {
		margin-bottom: 1.5rem;

		ul {
			margin-left: 0;
			margin-bottom: 0;

			li {
				display: inline-block;
				list-style: none;
				margin-right: 1rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	&__t1-text {
		p {
			margin-bottom: 3rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__f1 {
		&.style-contact-form-1 {
			box-shadow: 0 0 32px rgba(9, 35, 39, 0.1);
			padding: 5rem;

			.wpcf7 {
				.screen-reader-response {
					display: none;
				}
			}

			.wpcf7-form {
				> .grid-x {
					margin-bottom: 2rem;

					&:last-child {
						margin-bottom: 0;
					}

					> p {
						display: flex;
						flex-flow: row wrap;
					}
				}

				h2,
				h3 {
					@extend %o-title-bold-25;
				}

				label {
					@include cell();
					@include shrink();
					font-size: 1.1rem;
					line-height: 1;
					width: 12.5rem;
					margin-top: 1.1rem;

					span {
						color: #f00;
						font: inherit;
					}
				}

				.wpcf7-form-control-wrap {
					@include cell();
					@include auto();
				}

				.wpcf7-not-valid-tip {
					color: #f00;
					font-size: 1.1rem;
				}

				input,
				textarea {
					background-color: #fff;
					border: 1px solid #ebebeb;
					border-radius: 2.4rem;
					box-shadow: 0 0 0 transparent;
					font-family: $font-text;
					font-size: 1.2rem;
					padding: 0.5rem 1.5rem;
					margin-bottom: 0;
					width: 100%;

					&:focus {
						box-shadow: 0 0 0 transparent;
					}

					@each $prefix in $placeholder_prefixes {
						&#{$prefix} {
							color: inherit;
							font: inherit;
							opacity: 1;

							&:focus {
								box-shadow: 0 0 0 transparent;
							}
						}
					}
				}

				input {
					line-height: 3.8rem;
					height: 3.8rem;
				}

				textarea {
					min-height: 15rem;
				}

				.cf7-submit {
					text-align: left;

					input {
						$trans: 0.3s ease 0s;

						@extend %o-title-bold-16;
						background-color: $color-secondary;
						border-radius: 0.8rem;
						box-sizing: border-box;
						color: $color-white-1;
						font-family: $font-lora;
						display: inline-block;
						letter-spacing: 5%;
						line-height: 1.2;
						width: auto;
						height: auto;
						min-width: 19.5rem;
						padding: 1.6rem 2.4rem;
						text-align: center;
						text-decoration: none;
						text-transform: uppercase;
						transition: background-color $trans, color $trans;
						cursor: pointer;

						&:hover {
							background-color: $color-primary;
							color: $color-white-1;
							text-decoration: none;
						}
					}
				}

				.wpcf7-response-output {
					margin-top: 2rem;
					font-size: 1.3rem;

					&--invalid {
						color: #f00;
					}

					&--success {
						color: #006615;
					}
				}
			}
		}

		&.style-hubspot-1 {
			box-shadow: 0 0 32px rgba(9, 35, 39, 0.1);
			padding: 5rem;

			&.color-text-white {
				.hs-richtext {
					&.hs-main-font-element {
						> * {
							color: $color-white-1;
						}
					}
				}

				.hs-form-field {
					> label {
						color: $color-white-1;
					}

					input {
						&::placeholder {
							color: $color-white-1;
						}
					}
				}
			}

			.hbspt-form {
				background-color: transparent;
				border-radius: 0;
				padding: 0;

				.submitted-message {
					color: $color-green-1;
					font-size: 1.8rem;

					@include breakpoint(767px down) {
						font-size: 1.6rem;
					}
				}
			}

			// FORM COLUMNS
			.form-columns-1 {
				.hs-form-field {
					input,
					textarea {
						width: 100% !important;
					}
				}
			}

			.form-columns-2 {
				.hs-form-field {
					width: 100%;
					padding-left: 0;
					padding-right: 0;
				}
			}

			// MAIN TITLE
			.hs-richtext {
				&.hs-main-font-element {
					> * {
						font-family: $font-text !important;
						font-size: 2.2rem !important;
						font-weight: 600 !important;
						line-height: 1.3;
						margin-bottom: 1.2rem !important;

						span {
							color: inherit !important;
							font: inherit !important;
							font-size: 2.7rem !important;
						}
					}
				}
			}

			// FIELDS
			.hs-form-field {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				margin-bottom: 1.8rem;

				> label {
					@include cell();
					@include shrink();
					width: 12.5rem;

					span {
						font-size: 1.8rem;
						font-weight: 500;
						line-height: 1;

						&.hs-form-required {
							color: #f00;
						}
					}
				}

				.input {
					@include cell();
					@include auto();
				}

				input,
				textarea {
					background-color: #fff;
					border: 1px solid #ebebeb;
					border-radius: 2.4rem;
					box-shadow: 0 0 0 transparent;
					font-family: $font-text;
					font-size: 1.8rem;
					margin-bottom: 0;
					width: 100%;

					&:focus {
						box-shadow: 0 0 0 transparent;
					}

					@each $prefix in $placeholder_prefixes {
						&#{$prefix} {
							color: inherit;
							font: inherit;
							opacity: 1;

							&:focus {
								box-shadow: 0 0 0 transparent;
							}
						}
					}
				}

				textarea {
					min-height: 15rem;
				}

				// FIELD SUBMISSION ERROR
				.inputs-list {
					@include cell();
					margin-top: 1rem;
					margin-bottom: 1rem;
					margin-left: 0;
					padding-left: 9.5rem;

					@include breakpoint(767px down) {
						padding-left: 0;
					}

					label {
						color: #f00;
						font-size: 1.6rem;

						@include breakpoint(767px down) {
							font-size: 1.4rem;
							margin-bottom: 0;
							text-align: center;
							width: 100%;
						}
					}

					li {
						list-style-type: none;
					}

					&.hs-error-msgs {
						padding-left: 0;
					}
				}
			}

			// SUBMISSION ERROR
			.hs_error_rollup {
				ul {
					margin-top: 1rem;
					margin-bottom: 1rem;
					padding-left: 9.5rem;

					@include breakpoint(767px down) {
						padding-left: 0;
					}

					label {
						color: pink;
						font-size: 1.6rem;

						@include breakpoint(767px down) {
							font-size: 1.4rem;
							margin-bottom: 0;
							text-align: center;
							width: 100%;
						}
					}

					&.hs-error-msgs {
						padding-left: 0;
					}
				}
			}

			.hs-error-msgs {
				padding-left: 0;
			}

			// SUBMIT BUTTON
			.hs-submit {
				$trans: 0.3s ease 0s;

				text-align: left;

				input {
					// background-color: $color-teal-3;
					border-radius: 10rem;
					box-sizing: border-box;
					color: $color-white-1;
					cursor: pointer;
					display: inline-block;
					font-family: $font-text;
					font-size: 1.6rem;
					font-weight: 600;
					line-height: 1.2;
					min-width: 19.5rem;
					padding: 1.5rem 3.5rem;
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					transition: background-color $trans, border-color $trans, color $trans;

					&:hover {
						// background-color: $color-teal-1;
						text-decoration: none;
					}
				}
			}
		}
	}
}
