.photo-content-d {
	$this: &;

	&.col-gap-60 {
		&.content-left {
			#{$this}__content {
				padding-right: 7.2rem;
				padding-left: 0;

				@include breakpoint(1080 down) {
					padding-right: 7.2rem;
					margin-bottom: 2rem;
				}

				@include breakpoint(809px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__content {
				padding-left: 7.2rem;
				padding-right: 0;

				@include breakpoint(1080 down) {
					padding-left: 7.2rem;
				}

				@include breakpoint(809px down) {
					padding-left: 0;
				}
			}

			#{$this}__media {
				@include breakpoint(809px down) {
					margin-bottom: 2rem;
				}
			}
		}
	}

	&.col-gap-150 {
		&.content-left {
			#{$this}__content {
				padding-right: 15rem;

				@include breakpoint(809px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__content {
				padding-left: 15rem;
				padding-right: 0;

				@include breakpoint(809px down) {
					padding-left: 0;
				}
			}
		}
	}

	&.content-left {
		#{$this}__media {
			order: 2;
			justify-content: right;
			text-align: right;
		}

		#{$this}__content {
			order: 1;
			padding-left: 0;
		}
	}

	&.content-right {
		#{$this}__content {
			padding-left: 7.2rem;
			padding-right: 0;

			@include breakpoint(809px down) {
				padding-left: 0;
			}
		}
	}

	&.content-width-50p {
		#{$this}__content {
			width: 50%;

			@include breakpoint(809px down) {
				width: 100%;
			}
		}
	}

	&.content-max-width-300 {
		&.col-gap-60 {
			#{$this}__content {
				@include breakpoint(810px up) {
					max-width: 36rem;
				}
			}
		}

		&.col-gap-150 {
			#{$this}__content {
				@include breakpoint(810px up) {
					max-width: 45rem;
				}
			}
		}
	}

	&.content-max-width-600 {
		&.col-gap-60 {
			#{$this}__content {
				@include breakpoint(810px up) {
					max-width: 36rem;
				}

				@include breakpoint(1024px up) {
					max-width: 68rem;
				}
			}
		}

		&.col-gap-150 {
			#{$this}__content {
				@include breakpoint(810px up) {
					max-width: 45rem;
				}

				@include breakpoint(1024px up) {
					max-width: 75rem;
				}
			}
		}
	}

	&.media-half-bleed {
		@include breakpoint(1280px up) {
			position: relative;

			#{$this}__media {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&.media-padding-left-150 {
		#{$this}__media {
			@include breakpoint(1080px up) {
				padding-left: 15rem;
			}
		}
	}

	&.media-width-50p {
		#{$this}__media {
			width: 50%;

			@include breakpoint(809px down) {
				width: 100%;
			}
		}
	}

	&.media-width-auto {
		#{$this}__media {
			@include breakpoint(810px up) {
				@include auto();
			}
		}
	}

	&__media {
		> * {
			margin-bottom: 5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		video {
			max-width: 100%;
		}
	}

	&__bgi {
		@extend %background-image;
		min-height: 55rem;
		position: relative;
		width: 100%;

		@include breakpoint(1079px down) {
			min-height: 45rem;
		}

		@include breakpoint(809px down) {
			min-height: 35rem;
			margin-bottom: 2rem;
		}
	}

	&__img {
		@include breakpoint(809px down) {
			order: 2;
		}

		img {
			width: 100% !important;
		}
	}

	&__mil {
		@include breakpoint(810px up) {
			padding-left: 1.5rem;
		}

		@include breakpoint(809px down) {
			order: 1;
			padding-left: 1rem;
			margin-bottom: 2rem;

			&:first-child {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 2rem;
			}
		}

		&.col-2 {
			#{$this}__mil-col {
				width: 50%;
			}
		}
	}

	&__mil-row {
		$gap: 1.7rem;
		margin-left: -$gap;
		margin-right: -$gap;
	}

	&__mil-col {
		$gap: 1.7rem;

		padding-left: $gap;
		padding-right: $gap;
	}

	&__content {
		padding-right: 0;

		@include breakpoint(809px down) {
			margin-bottom: 2rem;
		}

		@include breakpoint(767 down) {
			text-align: center;
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-regular-54;

		line-height: 1.2;
		margin-bottom: 2rem;
		position: relative;

		@include breakpoint(767 down) {
			text-align: center;
		}

		&.color-text-white {
			color: $color-white-1;
		}

		&.font-size-36 {
			@include breakpoint(809 down) {
				font-size: 2.4rem;
			}

			@include breakpoint(810) {
				font-size: 2.6rem;
			}

			@include breakpoint(1081) {
				font-size: 3.6rem;
			}
		}
	}

	&__border {
		width: 10.5rem;
		height: 3px;
		margin-bottom: 2.5rem;

		@include breakpoint(767 down) {
			margin-left: auto;
			margin-right: auto;
		}

		&.color-default {
			background: rgb(0, 188, 0);
			background: linear-gradient(
				90deg,
				rgba(0, 188, 0, 1) 0%,
				rgba(240, 222, 48, 1) 100%
			);
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__text {
		position: relative;
		margin-bottom: 4rem;

		&:hover {
			#{$this}__tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		&.color-text-white {
			li,
			p {
				color: $color-white-1;
			}
		}

		&.font-size-24 {
			li,
			p {
				font-size: 2.4rem;

				@include breakpoint(809 down) {
					font-size: 2.2rem;
				}
			}
		}

		.button-box {
			margin-top: 2rem;

			&:first-child {
				margin-top: 0;
			}

			> a {
				margin-right: 1.5rem;

				@include breakpoint(1080px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
					text-align: center;
				}

				@include breakpoint(1079px down) {
					display: inline-block;
					margin-right: 1.5rem;
					margin-bottom: 0;
				}

				@include breakpoint(809px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
				}

				&.btn-link {
					margin-left: 3rem;

					@include breakpoint(1080px down) {
						margin-left: 0;
					}

					@include breakpoint(1079px down) {
						margin-left: 3rem;
					}

					@include breakpoint(809px down) {
						margin-left: 3rem;
					}
				}

				&:last-child {
					margin-right: 0;

					@include breakpoint(1080px down) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__aster {
		@include breakpoint(810px up) {
			position: relative;
		}
	}

	&__tooltip {
		$trans: 0.4s ease 0s;

		border-radius: 0.5rem;
		line-height: 0;
		width: 20rem;
		padding: 1rem;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		transition: opacity $trans, visibility $trans;

		&.color-text-white {
			#{$this}__tooltip-text {
				color: $color-white-1 !important;
			}
		}

		&.position-right {
			@include breakpoint(810px up) {
				margin-top: -0.5rem;
				margin-left: 1rem;
				top: 50%;
				left: 100%;
				transform: translateY(-50%);

				#{$this}__tooltip-arrow {
					border-top: 0.6rem solid transparent;
					border-bottom: 0.6rem solid transparent;
					border-right: 0.6rem solid transparent;
					margin-left: 1rem;
					top: 50%;
					right: 100%;
					transform: translateY(-50%);
				}
			}

			@include breakpoint(809px down) {
				margin-bottom: 1rem;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&__tooltip-text {
		font-size: 1rem !important;
		line-height: 1;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__tooltip-arrow {
		position: absolute;

		@include breakpoint(809px down) {
			border-left: 0.6rem solid transparent;
			border-right: 0.6rem solid transparent;
			border-top: 0.6rem solid transparent;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__ic {
		margin-bottom: 4rem;
	}

	&__ic-icon {
		margin-right: 1.5rem;
	}

	&__ic-text {
		p {
			font-size: 1.8rem;
		}
	}

	&__ic-title {
		@extend %o-title-medium-18;

		line-height: 1.2;
		margin-bottom: 1.5rem;
	}
}
