%bgd {
	// add z-index to create layers
	@for $i from 1 through 9 {
		&:nth-child(#{10-$i}) {
			z-index: $i;
		}
	}
}

.background-d {
	$this: &;

	position: relative;

	> section {
		position: relative;
		z-index: 10;
	}

	&.overflow-hidden {
		overflow: hidden;
	}

	&__default {
		overflow: hidden;
		@extend %background-image;
		@extend %fill-container;
		@extend %bgd;
	}
}
