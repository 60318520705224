.error-p {
	background-color: $color-black-1;

	&__content {
		padding: 16rem 0;
	}

	&__link {
		.btn {
			@include breakpoint(809px down) {
				min-width: 0;
			}
		}
	}

	&__title {
		@extend %o-title-medium-55;
		color: $color-white-1;
		margin-bottom: 3.3rem;
		margin-top: 1rem;

		@include breakpoint(809px down) {
			font-weight: 600;
			font-size: 2.7rem;
		}
	}
}
