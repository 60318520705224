.unique-bb {
	$this: &;
	$gap: 1rem;
	$trans: 0.3s ease 0s;

	line-height: 1;

	&__row {
		@include breakpoint(1024px up) {
			margin-left: -$gap;
			margin-right: -$gap;
		}
	}

	&__dropdown,
	&__slider,
	&__total,
	&__text {
		@include breakpoint(1024px up) {
			padding-left: $gap;
			padding-right: $gap;
		}
	}

	&__dropdown {
		margin-bottom: 3rem;

		@include breakpoint(1024px up) {
			width: 33.3333%;
		}

		label {
			color: $color-white-1;
			font-family: $font-text;
			font-size: 1.8rem;
			font-weight: 400;
			margin-bottom: 0.5rem;

			@include breakpoint(809 down) {
				text-align: center;
			}
		}

		select {
			border-radius: 100px;
			font-family: $font-text;
			font-size: 1.8rem;
			height: 4.5rem;
			padding: 0.5rem 2.5rem;
			appearance: none;
			-webkit-appearance: none;
			cursor: pointer;

			&::-ms-expand {
				display: none;
			}
		}
	}

	&__dropdown-select {
		position: relative;

		svg {
			width: 1.5rem;
			height: 0.8rem;
			pointer-events: none;
			margin-top: -0.8rem;
			position: absolute;
			top: 50%;
			right: 1.5rem;
		}
	}

	&__slider {
		margin-bottom: 3rem;

		label {
			font-family: $font-text;
			font-size: 1.8rem;
			font-weight: 700;
			margin-bottom: 2rem;
		}

		span {
			color: #44912d;
			font-weight: 600;
			font-size: 2rem;
		}

		input {
			accent-color: #44912d;
			width: 100%;
		}
	}

	&__total {
		hr {
			border-bottom: 1px solid $color-white-1;
			max-width: 31.5rem;
			margin: 1.5rem auto;
		}
	}

	&__total-value {
		color: $color-white-1;
		font-family: $font-text;
		font-size: 3.6rem;
		font-weight: 600;
	}

	&__total-text {
		color: $color-white-1;
		font-family: $font-text;
		font-size: 1.8rem;
		font-weight: 600;
	}

	&__text {
		margin-top: 4rem;

		p {
			a {
				border-radius: 10rem;
				box-sizing: border-box;
				color: $color-white-1;
				cursor: pointer;
				display: inline-block;
				font-family: $font-text;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 1.2;
				min-width: 19.5rem;
				padding: 1.5rem 3.5rem;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				transition: background-color $trans, border-color $trans, color $trans;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
