.o-video-d {
	$this: &;

	padding-top: 56.25%;
	position: relative;

	&.active {
		#{$this}__overlay {
			pointer-events: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	iframe,
	video {
		@extend %fill-container;
		z-index: 2;
	}

	&__overlay {
		$trans: 0.4s ease 0.4s;

		@extend %background-image;
		@extend %fill-container;
		background-color: $color-white-1;
		cursor: pointer;
		visibility: visible;
		opacity: 1;
		z-index: 3;
		transition: visibility $trans, opacity $trans;

		.btn-play {
			left: 50%;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__box {
		@extend %fill-container;
		background-color: $color-black-1;
		z-index: 2;
	}
}

.o-video-i {
	$this: &;

	position: relative;

	&.active {
		#{$this}__overlay {
			pointer-events: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	iframe,
	video {
		@extend %fill-container;
		z-index: 2;
	}

	&__img {
		opacity: 0;
		position: relative;
		z-index: 1;

		img {
			width: 100%;
		}

		@media only screen and (min-width: 810px) {
			padding-top: 56.25%;

			img {
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	&__overlay {
		$trans: 0.4s ease 0.4s;

		@extend %background-image;
		@extend %fill-container;
		background-color: $color-white-1;
		cursor: pointer;
		visibility: visible;
		opacity: 1;
		z-index: 3;
		transition: visibility $trans, opacity $trans;

		.btn-play {
			left: 50%;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__box {
		@extend %fill-container;
		background-color: $color-black-1;
		z-index: 2;
	}
}
