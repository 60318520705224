.column-content-ct {
	$this: &;

	&__row {
		margin-left: -1.5rem;
		width: calc(100% + 3rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		padding: 0 1.5rem;

		@include breakpoint(1023px down) {
			margin-bottom: 4rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(767px down) {
			padding: 0;
		}
	}
}
