// BLOG LIST ITEM
.o-card-bli {
	$this: &;

	background-color: $color-white-1;
	box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
	height: 100%;
	overflow: hidden;
	position: relative;

	&__image {
		@extend %background-image;
		width: 100%;
		padding-bottom: 56.25%;
	}

	&__content {
		height: 43.75%;
		padding: 2rem 4rem 4rem;

		@include breakpoint(1023px down) {
			padding: 2rem 4rem 6rem;
		}

		@include breakpoint(767px down) {
			height: auto;
		}
	}

	&__category {
		font-family: $font-text;
		font-size: 1.2rem;
		line-height: 1.6rem;
		letter-spacing: 0.3rem;
		text-transform: uppercase;
		color: $color-green-1;
		padding-bottom: 1rem;
	}

	&__title {
		color: $color-black-1;
		font-family: $font-title;
		font-size: 2.7rem;
		line-height: 3.2rem;
		margin-bottom: 2rem;

		@include breakpoint(1023px down) {
			font-size: 2rem;
		}
	}

	&__meta {
		width: 100%;
		padding-left: 4rem;
		padding-right: 4rem;
		position: absolute;
		left: 0;
		bottom: 2rem;

		span {
			color: #666666;
			font-family: $font-text;
			font-size: 1.3rem;
			letter-spacing: 0.088rem;
			line-height: 1.2;
			margin-right: 0.3rem;
		}
	}

	&__text {
		display: -webkit-box;
		height: 5rem;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		p {
			line-height: 1.6;
		}
	}
}
