.column-content-ca {
	$this: &;

	&.col-gap-20 {
		$gap: 1rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.col-gap-30 {
		$gap: 1.5rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.col-gap-40 {
		$gap: 2rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.col-gap-50 {
		$gap: 2.5rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.layout-card-1 {
		#{$this}__cell {
			@include breakpoint(1080px up) {
				box-shadow: 0 4px 32px rgba(9, 35, 39, 0.1);
			}
		}

		#{$this}__col {
			@include breakpoint(1080px up) {
				position: relative;

				&::before {
					background-color: #e1e2ef;
					display: block;
					content: "";
					width: 1px;
					height: 75%;
					position: absolute;
					top: 12.5%;
					right: 0;
					z-index: 1;
				}

				&:last-child {
					&::before {
						display: none;
					}
				}
			}
		}

		#{$this}__swiper {
			box-shadow: 0 4px 32px rgba(9, 35, 39, 0.1);
		}

		#{$this}__swiper-slide {
			@include breakpoint(768px up) {
				&::before {
					background-color: #e1e2ef;
					display: block;
					content: "";
					width: 1px;
					height: 80%;
					position: absolute;
					top: 10%;
					right: 0;
					z-index: 1;
				}

				&:last-child {
					&::before {
						display: none;
					}
				}
			}
		}
	}

	&.layout-logo-1 {
		&.col-gap-20 {
			$gap: 1rem;

			#{$this}__row {
				margin-left: -$gap;
				margin-right: -$gap;

				@include breakpoint(767px down) {
					margin-left: 0;
					margin-right: 0;
				}
			}

			#{$this}__col {
				padding-left: $gap;
				padding-right: $gap;
				margin-bottom: 0;

				@media screen and (min-width: 768px) and (max-width: 1024px) {
					width: 50%;
				}

				@include breakpoint(767px down) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		#{$this}__col {
			@include breakpoint(960px up) {
				@include shrink();
			}
		}
	}

	&.layout-logo-3 {
		&.col-gap-50 {
			$gap: 2.5rem;

			#{$this}__row {
				margin-left: -$gap;
				margin-right: -$gap;

				@include breakpoint(767px down) {
					margin-left: 0;
					margin-right: 0;
				}
			}

			#{$this}__col {
				padding-left: $gap;
				padding-right: $gap;
				margin-bottom: 5rem;

				@media screen and (min-width: 768px) and (max-width: 1024px) {
					width: 50%;
				}

				@include breakpoint(767px down) {
					padding-left: 0;
					padding-right: 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		#{$this}__col {
			&.col-20 {
				width: 20%;
	
				@include breakpoint(1079px down) {
					width: 50%;
				}
	
				@include breakpoint(767px down) {
					width: 100%;
				}
			}
	
			&.col-25 {
				width: 25%;
	
				@include breakpoint(1079px down) {
					width: 50%;
				}
	
				@include breakpoint(767px down) {
					width: 100%;
				}
			}
	
			&.col-33 {
				width: 33.3333%;
	
				@include breakpoint(1079px down) {
					width: 50%;
				}
	
				@include breakpoint(767px down) {
					width: 100%;
				}
			}
	
			&.col-50 {
				width: 50%;
	
				@include breakpoint(767px down) {
					width: 100%;
				}
			}
		}
	}

	&.layout-stage-funds {
		$gap: 11.5rem;

		#{$this}__row {
			@include breakpoint(1080px up) {
				margin-left: -$gap;
				margin-right: -$gap;
			}
		}

		#{$this}__col {
			@include breakpoint(1080px up) {
				padding-left: $gap;
				padding-right: $gap;
			}

			@include breakpoint(768px up) {
				@include shrink();
			}

			@include breakpoint(767px down) {
				margin-bottom: 5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&.break {
				@include breakpoint(1080px up) {
					display: none;
				}

				@media screen and (min-width: 768px) and (max-width: 1079px) {
					@include auto();
				}

				@include breakpoint(767px down) {
					display: none;
				}

				&:last-child {
					display: none;
				}
			}
		}
	}

	&.layout-banking-partners {
		$gap: 4.5rem;

		#{$this}__row {
			@include breakpoint(1080px up) {
				margin-left: -$gap;
				margin-right: -$gap;
			}
		}

		#{$this}__col {
			@include breakpoint(1080px up) {
				padding-left: $gap;
				padding-right: $gap;
			}

			@include breakpoint(768px up) {
				@include shrink();
			}

			@include breakpoint(767px down) {
				margin-bottom: 5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&.break {
				@include breakpoint(1080px up) {
					display: none;
				}

				@media screen and (min-width: 768px) and (max-width: 1079px) {
					@include auto();
				}

				@include breakpoint(767px down) {
					display: none;
				}

				&:last-child {
					display: none;
				}
			}
		}
	}

	&.layout-technology-partners {
		$gap: 4.5rem;

		#{$this}__row {
			@include breakpoint(1080px up) {
				margin-left: -$gap;
				margin-right: -$gap;
			}
		}

		#{$this}__col {
			@include breakpoint(1080px up) {
				padding-left: $gap;
				padding-right: $gap;
			}

			@include breakpoint(768px up) {
				@include shrink();
			}

			@include breakpoint(767px down) {
				margin-bottom: 5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&.col-50 {
				@media screen and (min-width: 768px) and (max-width: 900px) {
					max-width: 28rem;
				}
			}

			&.break {
				@include breakpoint(1080px up) {
					display: none;
				}

				@media screen and (min-width: 768px) and (max-width: 1079px) {
					@include auto();
				}

				@include breakpoint(767px down) {
					display: none;
				}

				&:last-child {
					display: none;
				}
			}
		}
	}

	&.enable-mobile-slider {
		@include breakpoint(1080px up) {
			#{$this}__slider {
				display: none;
			}
		}

		@include breakpoint(1079px down) {
			#{$this}__row {
				display: none;
			}
		}
	}

	&__col {
		&.col-20 {
			width: 20%;

			@include breakpoint(1079px down) {
				width: 100%;
			}
		}

		&.col-25 {
			width: 25%;

			@include breakpoint(1079px down) {
				width: 100%;
			}
		}

		&.col-33 {
			width: 33.3333%;

			@include breakpoint(1079px down) {
				width: 100%;
			}
		}

		&.col-50 {
			width: 50%;

			@include breakpoint(1079px down) {
				width: 100%;
			}
		}
	}

	&__slider {
		position: relative;
		padding-bottom: 5rem;
	}

	&__swiper-slide {
		box-sizing: border-box;
		height: auto;
	}

	&__swiper-pagination {
		bottom: -5rem;

		.swiper-pagination-bullet {
			background-color: $color-text;
			width: 1rem;
			height: 1rem;
			vertical-align: middle;

			&.swiper-pagination-bullet-active {
				background-color: $color-blue-1;
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}
