.o-cc-ca-c2 {
	$this: &;

	background-color: $color-white-1;
	box-shadow: 0px 4px 32px 0px rgba(9, 35, 39, 0.10);
	padding: 5rem 4rem;
	height: 100%;
	position: relative;
	cursor: pointer;

	&::before {
		background: linear-gradient(-90deg, #2578CA 0%, #00B9AE 100%);
		border-radius: 4px;
		display: block;
		content: '';
		width: 14.4rem;
		height: 8px;
		margin-left: -7.2rem;
		position: absolute;
		left: 50%;
		bottom: 0;
	}

	&__icon {
		margin-bottom: 1.5rem;
	}

	&__title {
		@extend %o-title-bold-25;
		color: $color-text;
		line-height: 1.2;
		margin-bottom: 1.5rem;
		margin-top: 0;
		text-align: center;
	}

	&__text {
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 8;
		-webkit-box-orient: vertical;
		overflow: hidden;

		p {
			color: $color-text;
			font-size: 1.6rem;
		}
	}
}
