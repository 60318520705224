.hero-c {
	$this: &;
	background-color: $color-green-1;
	overflow: hidden;
	padding: 9rem 0;
	position: relative;

	&:before {
		border-radius: 100%;
		content: "";
		height: 150rem;
		position: absolute;
		right: -45rem;
		top: -15rem;
		width: 150rem;

		@include breakpoint(1440px down) {
			right: -68rem;
		}

		@include breakpoint(1366px down) {
			right: -72rem;
		}

		@include breakpoint(1080px down) {
			right: -94rem;
		}

		@include breakpoint(1079px down) {
			display: none;
		}
	}

	&__grid {
		position: relative;
		z-index: 2;
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -0.7rem;
		width: calc(100% + 1.4rem);

		@include breakpoint(809px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		flex: 0 1 auto;
		margin: 0.7rem 0;
		padding: 0 0.7rem;
		width: 33.33%;

		@include breakpoint(1079px down) {
			width: 50%;
		}

		@include breakpoint(809px down) {
			padding: 0;
			width: 100%;
		}
	}

	&__col-box {
		height: 100%;

		&.box {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			background-color: $color-white-1;
			border-radius: 1rem;
			overflow: hidden;
			padding: 5rem 4.5rem;
		}
	}

	&__col-title {
		@extend %o-title-medium-36;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}

	&__col-no {
		flex: 0 1 auto;
		background-color: $color-yellow-1;
		border-radius: 100%;
		color: $color-white-1;
		font-family: $font-text;
		font-size: 1.6rem;
		font-weight: 600;
		height: 3.7rem;
		line-height: 3.7rem;
		text-align: center;
		width: 3.7rem;
	}

	&__col-text {
		flex: 0 1 auto;
		padding-left: 1.5rem;
		width: calc(100% - 3.7rem);

		p {
			color: $color-gray-1;
			line-height: 1.3;
		}
	}
}
