.o-sli-a-s1 {
	$this: &;

	&__spacer {
		@include breakpoint(1025px up) {
			max-width: 17rem;
		}

		@include breakpoint(1024px down) {
			max-width: 0;
		}
	}

	&__title {
		@extend %o-title-bold-30;
		margin-bottom: 2rem;

		@include breakpoint(1024px down) {
			margin-left: auto;
			margin-right: auto;
		}

		@include breakpoint(809px down) {
			font-size: 2.5rem;
		}
	}

	&__text {
		@include breakpoint(1024px down) {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
