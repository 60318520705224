.footer {
	$this: &;

	background-color: $color-primary;
	padding-top: 2rem;
	padding-bottom: 2rem;

	@include breakpoint(1024px down) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	&__lt {
		@include breakpoint(1025px up) {
			@include shrink();
			order: 3;
		}

		img {
			width: auto;
		}
	}

	&__spacer {
		@include breakpoint(1025px up) {
			@include auto();
			order: 2;
		}

		@include breakpoint(1024px down) {
			display: none;
		}
	}

	&__rt {
		@include breakpoint(1025px up) {
			@include shrink();
			order: 1;
		}

		@include breakpoint(1024px down) {
			margin-top: 1rem;
			text-align: center;
		}
	}

	&__so {
		ul {
			font-size: 0;
			margin-bottom: 0;
			margin-left: 0;

			li {
				display: inline-block;
				list-style-type: none;
				margin-right: 1rem;
				margin-bottom: 0;

				a {
					display: inline-block;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	&__cm {
		margin-top: 1rem;

		@include breakpoint(1024px down) {
			margin-top: 2rem;
		}
	}

	&__copy {
		@include breakpoint(1025px up) {
			@include shrink();
		}

		@include breakpoint(1024px down) {
			margin-top: 1rem;
			order: 2;
		}

		p {
			color: $color-white-1;
			font-size: 1.6rem;
		}
	}

	&__menu {
		@include breakpoint(1025px up) {
			@include shrink();
			margin-left: 1rem;
		}

		@include breakpoint(1024px down) {
			order: 1;
		}

		ul {
			font-size: 0;
			margin-bottom: 0;
			margin-left: 0;

			li {
				color: $color-white-1;
				display: inline-block;
				font-size: 1.6rem;
				line-height: 1;
				list-style-type: none;
				vertical-align: middle;
				padding: 0.5rem 1rem;
				margin-bottom: 0;
				position: relative;

				@include breakpoint(1024px down) {
					&:first-child {
						&::before {
							display: none;
						}
					}
				}

				&::before {
					background-color: $color-white-1;
					display: block;
					content: "";
					width: 1px;
					height: 2.4rem;
					position: absolute;
					top: 2px;
					left: 0;
				}

				a {
					color: inherit;
					font: inherit;
				}
			}
		}
	}
}
