// Fonts
$font-merriweather: "Merriweather", serif;
$font-lora: "Lora", serif;

$font-title: "Merriweather", serif;
$font-text: "Lora", serif;

$placeholder_prefixes: (
	"::-webkit-input-placeholder",
	"::-moz-placeholder",
	":-ms-input-placeholder",
	":-moz-placeholder"
);

// Theme Colors

$color-primary: #05204a;
$color-secondary: #f72585;
$color-text: #092327;
$color-background-light: #edeefc;
$color-light-blue: #2578ca;
$color-purple: #3a0ca3;
$color-jet-1: #323232;

$color-white-1: #ffffff;
$color-black-1: #000000;
$color-gray-1: #121212;

$color-blue-1: #00d4ff;
$color-green-1: #00bc00;
$color-yellow-1: #f0de30;

// $font-text: #00808c;
