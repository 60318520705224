.popup-cc-ca-c2 {
	$this: &;

	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	transition: opacity 0.4s ease 0s, z-index 0s ease 0.4s;

	&.active {
		z-index: 100;
		opacity: 1;
		transition: opacity 0.4s ease 0s, z-index 0s ease 0s;
	}

	&.col-gap-100 {
		$gap: 5rem;

		#{$this}__row {
			@include breakpoint(768px up) {
				margin-left: -$gap;
				margin-right: -$gap;
			}
		}

		#{$this}__col {
			@include breakpoint(768px up) {
				padding-left: $gap;
				padding-right: $gap;
			}

			@include breakpoint(767px down) {
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__bg {
		background: rgba(0, 0, 0, 0.60);
		backdrop-filter: blur(5px);
		display: block;
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&__grid,
	&__cell {
		height: 100%;
	}

	&__grid {
		pointer-events: none;
		padding-top: 4rem;
		padding-bottom: 4rem;
		position: relative;
		z-index: 2;
	}

	&__box {
		background-color: #fff;
		max-height: 100%;
		padding: 4rem;
		overflow-x: hidden;
		overflow-y: auto;
		pointer-events: all;
	}

	&__col {
		&.profile {
			position: relative;

			@include breakpoint(768px up) {
				max-width: 25rem;
			}

			@include breakpoint(767px down) {
				padding-bottom: 3.5rem;
			}

			&::before {
				background: linear-gradient(180deg, #2578CA 0%, #00B9AE 100%);
				display: block;
				content: '';
				position: absolute;

				@include breakpoint(768px up) {
					width: 2px;
					height: 100%;
					right: 0;
					bottom: 0;
				}

				@include breakpoint(767px down) {
					width: 100%;
					height: 2px;
					left: 0;
					bottom: 0;
				}
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				@extend %o-title-bold-25;
				margin-top: 1.5rem;
				margin-bottom: 0;
			}

			@include breakpoint(768px up) {
				@include shrink();
			}
		}

		&.bio {
			@include breakpoint(768px up) {
				@include auto();
			}

			ul, ol {
				margin-left: 3rem;
				list-style-position: outside;
			}
		}
	}
}
