.slider-r {
	$this: &;

	padding: 2.2rem 0;
	position: relative;

	&__grid {
		position: relative;
	}

	&__item-content {
		&.color-text-white {
			p {
				color: $color-white-1;
			}
		}

		@include breakpoint(767 down) {
			margin-bottom: 1rem;
		}
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		cursor: pointer;
		display: inline-block;
		position: absolute;
		top: 50%;
		transform: translate(0px, -50%);
		transition: opacity 0.3s;
		width: auto;
		z-index: 1;

		&:hover {
			opacity: 0.5;
		}
	}

	&__swiper-button-prev {
		left: 0;
	}

	&__swiper-button-next {
		right: 0;
	}
}
