.slider-l {
	$this: &;
	$trans: 0.4s ease 0s;

	&.layout-card-1 {
		.swiper-button-prev {
			@include breakpoint(1023px down) {
				left: -2rem;
			}
	
			@include breakpoint(767px down) {
				left: -1.5rem;
			}
		}
	
		.swiper-button-next {
			@include breakpoint(1023px down) {
				right: -2rem;
			}
	
			@include breakpoint(767px down) {
				right: -1.5rem;
			}
		}
	}

	&__slider {
		padding-bottom: 5rem;
		padding-left: 4rem;
		padding-right: 4rem;
		position: relative;

		@include breakpoint(767px down) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	&__swiper-slide {
		box-sizing: border-box;
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: 1.5rem;
		height: 3rem;
		margin-top: -1.5rem;

		&::after {
			color: $color-black-1;
			font-size: 2.8rem;
		}
	}

	&__swiper-pagination {
		bottom: 0 !important;

		.swiper-pagination-bullet {
			background-color: $color-text;
			width: 1rem;
			height: 1rem;
			vertical-align: middle;
			opacity: 1;

			&.swiper-pagination-bullet-active {
				background-color: $color-light-blue;
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}
