.search-category-d {
	$this: &;
	background-color: $color-white-1;
	position: relative;
	z-index: 3;

	&__row {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin-left: -1rem;
		padding: 3rem 0;
		width: calc(100% + 2rem);

		@include breakpoint(809px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__category {
		flex: 0 1 auto;
		padding: 0 1rem;
		width: 23.33%;

		@include breakpoint(809px down) {
			margin-bottom: 1rem;
			padding: 0;
			width: 100%;
		}

		&.newsroom,
		&.year {
			width: 35%;

			@include breakpoint(809px down) {
				margin-bottom: 1rem;
				padding: 0;
				width: 100%;
			}
		}

		&.active {
			#{$this}__category-inner {
				border-bottom-color: transparent;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}

			#{$this}__category-text {
				svg {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}

	&__category-inner {
		border-radius: 1.5rem;
		border: 0.1rem solid $color-gray-1;
		background: $color-white-1;
		cursor: pointer;
		padding: 0 3rem;
		position: relative;

		@include breakpoint(1079px down) {
			padding: 0 1.5rem;
		}
	}

	&__category-text {
		@extend %o-text-regular-16;
		color: $color-gray-1;
		line-height: 1.2;
		text-transform: capitalize;
		padding: 1rem 0;
		position: relative;

		svg {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%) rotate(0deg);
		}
	}

	&__category-list {
		display: none;
		background-color: $color-white-1;
		border: 0.1rem solid $color-gray-1;
		border-radius: 1.5rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top-color: transparent;
		left: -0.1rem;
		list-style: none;
		margin: 0;
		padding: 0 3rem;
		position: absolute;
		top: 100%;
		width: calc(100% + 0.2rem);
		z-index: 2;

		li {
			@extend %o-text-regular-16;
			color: $color-gray-1;
			line-height: 1.2;
			margin-bottom: 1.5rem;
			text-transform: capitalize;

			&:hover {
				color: $color-green-1;
			}

			&:last-child {
				margin-bottom: 3rem;
			}
		}
	}

	&__search {
		flex: 0 1 auto;
		padding: 0 1rem;
		position: relative;
		width: 30%;

		@include breakpoint(809px down) {
			padding: 0;
			width: 100%;
		}
	}

	&__form-field {
		position: relative;

		svg {
			right: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		input {
			@extend %o-text-regular-16;
			background-color: transparent;
			border: 0;
			border-bottom: 0.1rem solid $color-gray-1;
			border-radius: 0 !important;
			box-shadow: none;
			color: $color-gray-1;
			height: auto;
			margin: 0;
			padding: 0.8rem 4rem 0.8rem 0;
			width: 100%;

			@each $prefix in $placeholder_prefixes {
				&#{$prefix} {
					color: $color-gray-1;
					font: inherit;
					opacity: 1;
				}
			}

			&:focus {
				background-color: transparent;
				border: 0;
				border-bottom: 0.1rem solid $color-gray-1;
				box-shadow: none;

				@each $prefix in $placeholder_prefixes {
					&#{$prefix} {
						color: $color-gray-1;
						font: inherit;
						opacity: 0;
					}
				}
			}
		}
	}

	&__result {
		background-color: $color-white-1;
		border-bottom: 0.1rem solid $color-gray-1;
		border-left: 0.1rem solid $color-gray-1;
		border-right: 0.1rem solid $color-gray-1;
		display: none;
		left: 1rem;
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 100%;
		width: calc(100% - 2rem);
		z-index: 2;

		li {
			border-bottom: 0.1rem solid $color-gray-1;

			p,
			a {
				@extend %o-text-regular-16;
				color: $color-gray-1;
				display: block;
				line-height: 1.2;
				margin-bottom: 0;
				padding: 0.5rem 1rem;
			}

			a {
				&:hover {
					text-decoration: none;
				}
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}
