%o-title-light-36 {
	font-family: $font-title;
	font-weight: 300;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-regular-22 {
	font-family: $font-title;
	font-size: 2.2rem;
	font-weight: 400;
}

%o-title-regular-24 {
	font-family: $font-title;
	font-size: 2.4rem;
	font-weight: 400;
}

%o-title-regular-36 {
	font-family: $font-title;
	font-weight: 400;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 2.4rem;
	}
}

%o-title-regular-40 {
	font-family: $font-title;
	font-size: 4rem;
	font-weight: 400;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-regular-45 {
	font-family: $font-title;
	font-weight: 400;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 26px;
	}

	@include breakpoint(1081) {
		font-size: 45px;
	}
}

%o-title-regular-54 {
	font-family: $font-title;
	font-size: 5.4rem;
	font-weight: 400;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809px down) {
		font-size: 2.4rem;
	}
}

%o-title-medium-14 {
	font-family: $font-title;
	font-size: 1.4rem;
	font-weight: 500;
}

%o-title-medium-16 {
	font-family: $font-title;
	font-size: 1.6rem;
	font-weight: 500;
}

%o-title-medium-18 {
	font-family: $font-title;
	font-size: 1.8rem;
	font-weight: 500;
}

%o-title-medium-22 {
	font-family: $font-title;
	font-size: 2.2rem;
	font-weight: 500;
}

%o-title-medium-36 {
	font-family: $font-title;
	font-weight: 500;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-medium-12 {
	font-family: $font-title;
	font-size: 1.2rem;
	font-weight: 500;
}

%o-title-medium-16 {
	font-family: $font-title;
	font-size: 1.6rem;
	font-weight: 500;
}

%o-title-medium-18 {
	font-family: $font-title;
	font-size: 1.8rem;
	font-weight: 500;
}

%o-title-medium-22 {
	font-family: $font-title;
	font-size: 2.2rem;
	font-weight: 500;
}

%o-title-medium-24 {
	font-family: $font-title;
	font-size: 2.4rem;
	font-weight: 500;
}

%o-title-medium-28 {
	font-family: $font-title;
	font-size: 2.8rem;
	font-weight: 500;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-medium-36 {
	font-family: $font-title;
	font-weight: 500;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-medium-54 {
	font-family: $font-title;
	font-size: 5.4rem;
	font-weight: 500;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809px down) {
		font-size: 2.4rem;
	}
}

%o-title-medium-55 {
	font-family: $font-title;
	font-size: 5.5rem;
	font-weight: 500;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-semibold-14 {
	font-family: $font-title;
	font-size: 1.4rem;
	font-weight: 500;
}

%o-title-semibold-16 {
	font-family: $font-title;
	font-size: 1.6rem;
	font-weight: 500;
}

%o-title-semibold-18 {
	font-family: $font-title;
	font-size: 1.8rem;
	font-weight: 500;
}

%o-title-semibold-22 {
	font-family: $font-title;
	font-size: 2.2rem;
	font-weight: 500;
}

%o-title-semibold-36 {
	font-family: $font-title;
	font-weight: 500;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-semibold-12 {
	font-family: $font-title;
	font-size: 1.2rem;
	font-weight: 600;
}

%o-title-semibold-16 {
	font-family: $font-title;
	font-size: 1.6rem;
	font-weight: 600;
}

%o-title-semibold-18 {
	font-family: $font-title;
	font-size: 1.8rem;
	font-weight: 600;
}

%o-title-semibold-22 {
	font-family: $font-title;
	font-size: 2.2rem;
	font-weight: 600;
}

%o-title-semibold-24 {
	font-family: $font-title;
	font-size: 2.4rem;
	font-weight: 600;

	@include breakpoint(809 down) {
		font-size: 2.2rem;
	}
}

%o-title-semibold-28 {
	font-family: $font-title;
	font-size: 2.8rem;
	font-weight: 600;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-semibold-36 {
	font-family: $font-title;
	font-weight: 600;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-semibold-40 {
	font-family: $font-title;
	font-weight: 600;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 26px;
	}

	@include breakpoint(1081) {
		font-size: 40px;
	}
}

%o-title-semibold-54 {
	font-family: $font-title;
	font-size: 5.4rem;
	font-weight: 600;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809px down) {
		font-size: 2.4rem;
	}
}

%o-title-semibold-55 {
	font-family: $font-title;
	font-size: 5.5rem;
	font-weight: 600;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809px down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-12 {
	font-family: $font-title;
	font-size: 1.2rem;
	font-weight: 700;
}

%o-title-bold-14 {
	font-family: $font-title;
	font-size: 1.4rem;
	font-weight: 700;
}

%o-title-bold-16 {
	font-family: $font-title;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-title-bold-18 {
	font-family: $font-title;
	font-size: 1.8rem;
	font-weight: 700;
}

%o-title-bold-22 {
	font-family: $font-title;
	font-size: 2.2rem;
	font-weight: 700;
}

%o-title-bold-24 {
	font-family: $font-title;
	font-size: 2.4rem;
	font-weight: 700;
}

%o-title-bold-25 {
	font-family: $font-title;
	font-size: 2.5rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2rem;
	}
}

%o-title-bold-28 {
	font-family: $font-title;
	font-size: 2.8rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-30 {
	font-family: $font-title;
	font-size: 3rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-32 {
	font-family: $font-title;
	font-size: 3.2rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-34 {
	font-family: $font-title;
	font-size: 3.4rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-35 {
	font-family: $font-title;
	font-size: 3.5rem;
	font-weight: 700;
}

%o-title-bold-36 {
	font-family: $font-title;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-bold-38 {
	font-family: $font-title;
	font-size: 3.8rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-42 {
	font-family: $font-title;
	font-size: 4.2rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-48 {
	font-family: $font-title;
	font-size: 4.8rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-bold-54 {
	font-family: $font-title;
	font-size: 5.4rem;
	font-weight: 700;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809px down) {
		font-size: 3.8rem;
	}
}

%o-title-bold-60 {
	font-family: $font-title;
	font-size: 6rem;
	font-weight: 700;

	@include breakpoint(1079px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(809px down) {
		font-size: 3.8rem;
	}
}

%o-title-bold-65 {
	font-family: $font-title;
	font-size: 6.5rem;
	font-weight: 700;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}

%o-title-extrabold-36 {
	font-family: $font-title;
	font-weight: 800;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}

	@include breakpoint(810) {
		font-size: 2.6rem;
	}

	@include breakpoint(1081) {
		font-size: 3.6rem;
	}
}

%o-title-extrabold-65 {
	font-family: $font-title;
	font-size: 6.5rem;
	font-weight: 800;

	@include breakpoint(809 down) {
		font-size: 2.4rem;
	}
}
