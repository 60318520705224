.o-cc-ca-c1 {
	$this: &;

	padding: 4rem;
	height: 100%;

	&__title {
		@extend %o-title-bold-25;
		margin-bottom: 0;
	}

	&__text {
		margin-top: 2rem;

		li,
		p {
			font-size: 1.6rem;
		}
	}

	&__icon {
		margin-top: 2rem;
	}
}
