.o-sli-l-c1 {
	$this: &;
	$trans1: 0.4s ease 0s;
	$trans2: 0.4s ease 0.2s;

	position: relative;

	&:hover {
		#{$this}__logo1 {
			top: 0;
			transform: translate(-50%, 0%);

			img {
				height: 6rem !important;
			}
		}

		#{$this}__text {
			opacity: 1;
			transition: opacity $trans2;
		}
	}

	&__logo1 {
		text-align: center;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: transform $trans1, top $trans1;

		img {
			transition: height $trans1;
		}
	}

	&__logo2 {
		opacity: 0;

		img {
			height: 6rem;
		}
	}

	&__text {
		opacity: 0;
		transition: opacity $trans1;

		li,
		p {
			font-size: 1.6rem;
		}

		p {
			max-width: 23rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
