.o-cc-ca-l3 {
	$this: &;
	position: relative;

	&__text {
		margin-top: 2rem;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@extend %o-title-bold-25;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}

	&__block-link {
		content: "";
		cursor: pointer;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}
