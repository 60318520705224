.tab-content-l {
	$this: &;
	$trans: 0.4s ease 0s;

	&__header {
		margin-bottom: 3rem;

		@include breakpoint(767px down) {
			margin-bottom: 2rem;
		}
	}

	&__header-item {
		position: relative;

		@include breakpoint(768px up) {
			@include shrink();
			margin-bottom: 2rem;
		}

		@include breakpoint(767px down) {
			text-align: center;
		}

		&::before {
			border-bottom: 1px solid rgba(50, 50, 50, 0.6);
			display: block;
			content: "";
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			transition: border-bottom-color $trans;
		}

		span {
			@extend %o-title-bold-24;
			background-color: transparent;
			color: $color-jet-1;
			display: inline-block;
			cursor: pointer;
			line-height: 1;
			text-align: center;
			text-transform: uppercase;
			padding: 1rem 2rem;
			opacity: 0.6;
			transition: opacity $trans;

			&::before {
				display: block;
				content: attr(title);
				font-weight: 700;
				height: 0;
				overflow: hidden;
				visibility: hidden;
			}
		}

		&:hover,
		&.active {
			&::before {
				border-bottom-color: $color-purple;
				border-bottom-width: 2px;
			}

			span {
				font-weight: 700;
				opacity: 1;
			}
		}
	}

	&__tab-item {
		display: none;

		&.active {
			display: block;
		}
	}

	&__ti {
		@extend %o-title-bold-38;
		color: $color-black-1;
		margin-bottom: 5rem;

		@include breakpoint(767px down) {
			margin-bottom: 3rem;
		}

		&.text-transform-uppercase {
			text-transform: uppercase;
		}
	}

	&__lc1 {
		&.col-gap-24 {
			$gap: 1.2rem;

			#{$this}__lc1-row {
				@include breakpoint(768px up) {
					margin-left: -$gap;
					margin-right: -$gap;
				}
			}

			#{$this}__lc1-col {
				margin-bottom: #{$gap * 2};

				@include breakpoint(768px up) {
					padding-left: $gap;
					padding-right: $gap;
				}

				@include breakpoint(767px down) {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__lc1-col {
		&.col-20 {
			width: 20%;

			@include breakpoint(768px down) {
				width: 50%;
			}

			@include breakpoint(767px down) {
				width: 100%;
			}
		}
	}
}
