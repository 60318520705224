.o-cc-ca-l1 {
	$this: &;

	position: relative;

	&:hover {
		#{$this}__logo1 {
			top: 0;
			transform: translate(-50%, 0%);

			img {
				height: 6rem !important;
			}
		}

		#{$this}__text {
			opacity: 1;
			transition: opacity 0.4s ease 0.2s;
		}
	}

	&__logo1 {
		text-align: center;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: transform 0.4s ease 0s, top 0.4s ease 0s;

		img {
			object-fit: contain;
			transition: height 0.4s ease 0s;
		}
	}

	&__logo2 {
		opacity: 0;

		img {
			height: 6rem;
		}
	}

	&__text {
		opacity: 0;
		transition: opacity 0.2s ease 0s;

		li,
		p {
			font-size: 1.6rem;
		}

		p {
			max-width: 23rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
