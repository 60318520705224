.intro-content-d {
	$this: &;

	&.layout-quote {
		#{$this}__cell {
			padding: 9rem 12.4%;

			@include breakpoint(767px down) {
				padding: 5rem 10%;
			}
		}
	}

	&__border {
		&.border-radius-4 {
			border-radius: 4px;
		}

		&.height-4 {
			height: 4px;
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__title {
		@extend %o-title-bold-42;
		line-height: 1.2;
		margin-bottom: 2.5rem;

		&:last-child {
			margin-bottom: 0;
		}

		&.color-text-white {
			color: $color-white-1;
		}

		&.font-size-54 {
			font-size: 5.4rem;

			@include breakpoint(1079px down) {
				font-size: 4.6rem;
			}

			@include breakpoint(809px down) {
				font-size: 3.8rem;
			}
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}

		&.text-transform-uppercase {
			text-transform: uppercase;
		}
	}

	&__text {
		margin-bottom: 2.5rem;

		&:last-child {
			margin-bottom: 0;
		}

		&.color-text-white {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			li,
			p {
				color: $color-white-1;
			}
		}

		&.font-size-24 {
			li,
			p {
				font-size: 2.4rem;
			}
		}

		&.line-height-125 {
			li,
			p {
				line-height: 1.25;
			}
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}

		p {
			margin-bottom: 3rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
