@include font-face(
	"Merriweather",
	"fonts/merriweather/Merriweather-Regular",
	400,
	normal,
	ttf
);

@include font-face(
	"Merriweather",
	"fonts/merriweather/Merriweather-Italic",
	400,
	italic,
	ttf
);

@include font-face(
	"Merriweather",
	"fonts/merriweather/Merriweather-Bold",
	700,
	normal,
	ttf
);

@include font-face(
	"Merriweather",
	"fonts/merriweather/Merriweather-BoldItalic",
	700,
	italic,
	ttf
);

@include font-face(
	"Lora",
	"fonts/lora/Lora-VariableFont_wght",
	400,
	normal,
	ttf
);

@include font-face(
	"Lora",
	"fonts/lora/Lora-VariableFont_wght",
	bold,
	normal,
	ttf
);

@include font-face(
	"Lora",
	"fonts/lora/Lora-Italic-VariableFont_wght",
	400,
	italic,
	ttf
);

@include font-face(
	"Lora",
	"fonts/lora/Lora-Italic-VariableFont_wght",
	bold,
	italic,
	ttf
);
