.slider-a {
	$this: &;
	$trans: 0.4s ease 0s;

	overflow: hidden;

	@include breakpoint(1024px down) {
		padding-bottom: 11rem;
	}

	&__grid {
		@include breakpoint(1024px down) {
			max-width: 100%;
		}
	}

	&__slider {
		position: relative;
	}

	&__slider-bg {
		background-color: #fff;
		width: 500%;
		height: 100%;
		position: absolute;
		top: 0;
		left: -500%;
	}

	&__slider-next {
		width: 75%;
		pointer-events: none;
		position: absolute;
		top: 50%;
		left: 0;

		@include breakpoint(1024px down) {
			width: 100%;
			top: 100%;
		}
	}

	&__slider-wrapper {
		background-color: #fff;
		border-radius: 0 40rem 40rem 0;
		padding-top: 10rem;
		padding-bottom: 15rem;
		position: relative;
		overflow: hidden;

		@include breakpoint(1025px up) {
			width: 75%;
		}

		@include breakpoint(1024px down) {
			border-radius: 0 0 40rem 40rem;
			width: 100%;
			padding-top: 7.5rem;
			padding-bottom: 20rem;
		}

		@include breakpoint(767px down) {
			padding-top: 5rem;
		}
	}

	&__slider-title {
		margin-bottom: 3rem;
	}

	&__slider-title-text {
		@extend %o-title-bold-42;
		line-height: 1.2;

		@include breakpoint(809px down) {
			font-size: 4.2rem;
		}

		@include breakpoint(1024px down) {
			margin-left: auto;
			margin-right: auto;
			max-width: none !important;
			text-align: center;
			padding-left: 12.5%;
			padding-right: 12.5%;
		}
	}

	&__slider-title-spacer {
		@include breakpoint(1025px up) {
			max-width: 17rem;
		}

		@include breakpoint(1024px down) {
			max-width: 0;
		}
	}

	&__swiper-slide {
		box-sizing: border-box;

		@include breakpoint(1024px down) {
			text-align: center;
			padding-left: 12.5%;
			padding-right: 12.5%;
		}
	}

	&__swiper-pagination {
		text-align: left;
		bottom: 9rem !important;

		@include breakpoint(1024px down) {
			text-align: center;
			bottom: 14rem !important;
		}

		.swiper-pagination-bullet {
			background-color: $color-text;
			width: 1rem;
			height: 1rem;
			vertical-align: middle;
			opacity: 1;

			&:first-child {
				margin-left: 0 !important;
			}

			&:last-child {
				margin-right: 0 !important;
			}

			&.swiper-pagination-bullet-active {
				background-color: $color-blue-1;
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}

	&__swiper-button-next {
		border: 1rem solid $color-background-light;
		border-radius: 50%;
		width: 28rem;
		height: 28rem;
		margin-top: -14rem;
		margin-right: -14rem;
		overflow: hidden;
		pointer-events: all;

		@include breakpoint(1024px down) {
			border: 0.5rem solid $color-background-light;
			width: 20rem;
			height: 20rem;
			margin-top: -10rem;
			margin-left: -10rem;
			margin-right: 0;
			left: 50%;
			right: auto;
		}

		div {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			&:nth-child(1) {
				background: linear-gradient(45deg, rgba(58,12,163,1) 0%, rgba(58,12,163,0) 100%);
				z-index: 2;
			}

			&:nth-child(2) {
				background-color: #000;
				transition: background-color $trans;
				z-index: 1;
			}
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 3;

			@media screen and (min-width: 1025px) {
				width: 6rem;
				height: 6rem;
				margin-top: -3rem;
				margin-left: -2.6rem;
			}
	
			@media screen and (max-width: 1024px) {
				width: 4.2rem;
				height: 4.2rem;
				margin-top: -2.1rem;
				margin-left: -1.7rem;
			}
		}
		
		&::after {
			display: none;
		}
	}
}