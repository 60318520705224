.o-cc-ct {
	$this: &;

	&__title {
		@extend %o-title-bold-60;
		line-height: 1;
		text-transform: none;
		margin-bottom: 1.5rem;
	}

	&__text {
		color: $color-text;

		p {
			@extend %o-text-regular-16;
		}
	}
}
