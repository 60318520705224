.o-bullet-orange-check {
	ul {
		li {
			list-style: none;
			margin-bottom: 15px !important;
			padding-left: 30px;
			position: relative;

			&:last-child {
				margin-bottom: 0 !important;
			}

			&:before {
				background-image: url("../../assets/images/orange-check.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				content: "";
				height: 20px;
				left: 0;
				position: absolute;
				top: 50%;
				width: 20px;
				transform: translateY(-50%);
			}
		}
	}
}
